
$SIGNATURE_COLOR: #00B0F0;

.Appbar {
    display: flex;
    position: fixed;
    flex-direction: row;
    flex-wrap: nowrap;
    bottom:0; left:0;
    z-index: 2;
    width: 100%;
    height: 3rem;
    background-color: white;
    //borderBottom: `3px ${SIGNATURE_COLOR} solid`,
    .Button {
        width: calc(25% - 8px);
        height: 2.5rem;
        margin: 0.25rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .AvatarButton {
        background-color: rgba(255,255,255,0.5);
        margin-top: 0.5rem;
        float: right;
        margin-right: 0.5rem;
        width: 2rem;
        height: 2rem;
        .Avatar {
            width: 2rem;
            height: 2rem;
        }
    }
    .Indicator {
        position: absolute;
        bottom: 0;
        width: calc(25% - 8px);
        height: 1;
        border-bottom: 2px $SIGNATURE_COLOR solid;
        transition: 400ms;
    }
};