$SIGNATURE_COLOR: rgb(0, 176, 240);

html:not([data-scroll='0']) {
    .SiteHeader {
        height: 2rem;
        background-color: white;
        color: black;

        &.HideClean {
            height: 0rem;
            overflow-y: hidden;
            color: white;
            z-index: inherit;
        }
        
        &.ForceToShow {
            height: 3rem;
            background-color: $SIGNATURE_COLOR;
            color: white;
        }
    }
    .BottomButtonBar {
        height: 2rem;
        background-color: white;

        &.HideClean {
            height: 0rem;
            overflow-y: hidden;
        }
        
        &.ForceToShow {
            height: 3rem;
            background-color: $SIGNATURE_COLOR;
            color: white;
        }
    }
    .Logo {
        display: none;
    }
}

html[data-scroll='0'] {
    .SiteHeader {
        height: 3rem;
        background-color: $SIGNATURE_COLOR;
    }
    .BottomButtonBar {
        height: 3rem;
        background-color: $SIGNATURE_COLOR;
    }
    .LogoSmall {
        display: none;
    }
    .MenuButton {
        color: white;
    }
}

.SiteHeader {
    color: white;
    position: fixed; top:0; left: 0;
    width: calc(100% - 2rem);
    transition: 300ms;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-left: 1rem;
    padding-right: 1rem;
    z-index: 99;
    
    .Logo {
        height: 100%;
    }
    .LogoSmall {
        height: 80%;
    }
    .MenuButton {
        height: 1rem;
        width: 1rem;
        padding: 0;

        svg {
            color: white;
        }
    }
}

.BottomButtonBar {
    color: white;
    position: fixed; bottom:0; left: 0;
    width: calc(100% - 2rem);
    transition: 300ms;
    display: flex;
    flex-direction: row;
    justify-content: stretch;
    align-items: center;
    padding-left: 1rem;
    padding-right: 1rem;
    z-index: 99;
    
    .Button {
        color: white;
    }

}

.PopupMenu {
    height: 300px;
    width: 80%;
    max-width: 400px;
    background-color: rgba(255,255,255,0.7);
    backdrop-filter: blur(10px);
    border-radius: 10px;
    transition: 300ms;

    position: absolute;
    left: calc(50% - 200px);
    bottom: -300px;

    &.Opened{
        bottom: 0px;
    }
}