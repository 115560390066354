.Service {
    width: 100%;
    height: 100%;
    overflow-y: hidden;
    position: relative;
    .TopBack {
        top: 0; left: 0; padding-top: 4rem;
        position: fixed;
        width: 100%;
        /* TopBacks height will goes to 60% from 100%; as scroll goes to calc(8rem + 80px) */
        //height: `${ gradient(progress; 100; 60) }%`;
        height: 300px;
        .ThumbnailContainer {
            position: absolute;
            top: 0; left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 0;
            .Thumbnail {
                flex-grow: 0;
                width: 150px;
            };
        };
        .Background {
            position: absolute;
            top: 0; left: 0;
            width: 100%;
            height: 150%;
            z-index: -1;
            object-fit: cover;
            filter: "blur(2px) brightness(60%)";
        };
    };
    .BottomContainer {
        width: 100%;
        overflow: auto;
        .BottomSlider {
            position: relative;
            width:  100%;
            max-width: 1000px;

            margin-left: auto;
            margin-right: auto;
            background-color: white;
            border-top-left-radius: 20px;
            border-top-right-radius: 20px;
            margin-top: 300px;
            margin-bottom: 40px;
            .FeedContainer {
                display: flex;
                align-items: center;
                justify-content: center;
                .GridFeed {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-wrap: wrap;
                    width: 100%;
                };
            };
        };
    };
    .TopButtonContainer {
        position: fixed;
        top: 0; left: 0; width: 100%; display: inline-block;
        .BackButton {
            float: left;
            color: white;
            padding-top: 10;
            padding-left: 10;
        };
        .MoreButton {
            float: right;
        };
    };
    .BottomButtons {
        position: fixed;
        bottom: 0; left: 0; width: 100%; display: inline-block;
        box-shadow: 0 -1px 12px rgba(0, 0, 0, 0.05), 0 -1px 12px rgba(0, 0, 0, 0.05);
        background-color: white;
        padding-top: 10;
        .Child {
            float: left;
            width: 33.333333%;
            display: flex;
            justify-content: center;
            align-items: center;
            //border: 1px lightgray solid;
            border-radius: 0;
            .Title {
                padding-bottom: 7px;
                padding-left: 5px;
            }
        }
    };
}